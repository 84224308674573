<template>
	<div v-if="hasManageContentPermission" class="hub-pages" :class="computedClass">
		<v-card class="elevation-0">
			<QDataTableHeader color="q_blue_1">
				<template #left>
					<div class="d-flex align-center q-display-3 white--text">
						{{ title }}
						<v-btn class="ml-1" dark icon @click="resetAndReload">
							<v-icon>fas fa-redo-alt</v-icon>
						</v-btn>
					</div>
				</template>

				<template #right>
					<v-spacer />
					<v-text-field
						v-model="search"
						:disabled="isDemoMode"
						label="Search"
						append-icon="icon-q-search"
						dark
						clearable
						dense single-line hide-details
						@keyup="maybeSearch"
						@click:append="refreshData"
						@click:clear="onSearchClear"
					/>
				</template>
			</QDataTableHeader>

			<div class="data-table-header-actions">
				<v-btn outlined color="#0083EB" class="px-3" @click="addNewPage">
					<v-icon small>fas fa-plus</v-icon>
					<v-divider vertical class="mx-1" color="#0083EB" />
					New Page
				</v-btn>
				<v-btn text color="#0083EB" class="px-3" :disabled="!available_filters" @click="toggleFilters">
					<v-icon small>fas fa-filter</v-icon>
					<v-divider vertical class="mx-1" :color="available_filters && '#0083EB'" />
					Filter Pages
				</v-btn>
			</div>

			<div class="data-table-container__row">
				<div class="data-table-container__col data-table-col">
					<HubPagesTable
						:id="entityId"
						:items="items"
						:options="options"
						:server-items-length="serverItemsLength"
						:loading="dataLoading"
						@update-options="options = $event"
						@view-audit="viewAudit"
						@copy-page="copyPage"
						@edit-page="editPage"
						@view-page="viewPage"
						@delete-page="confirmDeletePage"
					/>
				</div>

				<div v-show="showFilters" class="data-table-container__col filters-col">
					<QFilters
						ref="filters"
						v-model="filters"
						:keys="filter_keys"
						:available-filters="available_filters"
						dark hide-button column
					/>
				</div>
			</div>
		</v-card>

		<v-dialog v-if="doShowConfirmDeleteDialog" v-model="doShowConfirmDeleteDialog" width="95%" max-width="500px">
            <v-card>
                <v-card-title class="headline error" color="error">
                    <h4 class="q_white-text">Move <span v-if="allowDeletePagePermanently">(PERMANENT) </span>"{{ currentDeletedPage.title }}" to the trash?</h4>
                </v-card-title>
                <v-card-text class="pt-3">
                    <p class="body">Are you sure you want to <strong v-if="allowDeletePagePermanently">PERMANENTLY</strong> remove this content? This will unpublish the page, move it to the trash and any links to it will no longer work.</p>
                    <p class="caption">*You can use "FILTER PAGES", STATUS / Trash to change the status back to Published or Draft in the Editor.</p>
                </v-card-text>
                <v-divider />
                <v-card-actions class="py-4 px-4">
                    <v-btn color="error" outlined width="6.5rem" :disabled="pageDeleting" @click="deletePage">
						<v-progress-circular v-if="pageDeleting" class="mr-1" left size="16" width="2" indeterminate />
						Delete
					</v-btn>
                    <v-spacer />
                    <v-btn color="error" depressed @click="doShowConfirmDeleteDialog = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

		<HubPageEditorDialog
			v-if="doShowEditorDialog"
			v-model="doShowEditorDialog"
			:page="currentPage"
			:mode="hubPagesEditorMode"
			@saved="onHubPageSave"
		/>
	</div>

	<div v-else class="hub-pages">
		<v-alert type="error">You don't have permission</v-alert>
	</div>
</template>

<script>
import { debounce } from 'vue-debounce'

import QDataTableMixin from '@/components/datatables/QDataTableMixin.js'
import QDataTableHeader from '@/components/datatables/base/QDataTableHeader.vue'
import HubPagesTable from '../components/HubPagesTable.vue'
import HubPageEditorDialog from '../components/HubPageEditorDialog.vue'

import { getHubPages, deleteHubPage } from '../api/index'
import { EDITOR_MODE, defaultOptions, ALL_OPTION } from '../config/index'

export default {
    components: {
		QDataTableHeader,
		HubPagesTable,
		HubPageEditorDialog,
	},
    mixins: [QDataTableMixin],
	props: {
		title: { type: String, default: 'Hub Pages' },
	},
    data () {
        return {
			entity: 'hub-pages',
			firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
			dataLoading: false,
            responseData: [],
            server_available_filters: null,
            filters: {
                division: ALL_OPTION,
                status: ALL_OPTION,
            },
            options: { ...defaultOptions },
            serverItemsLength: undefined,

			currentDeletedPage: null,
			pageDeleting: false,
			doShowConfirmDeleteDialog: false,

			doShowEditorDialog: false,
			hubPagesEditorMode: EDITOR_MODE.new,
			currentPage: null,
        }
    },
	created () {
		this.refreshData()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		computedClass () {
			return [
				this.isMobileView && 'hub-pages--mobile'
			]
		},
		entityId () {
			return `${this.entity}_datatable`
		},
		filter_keys () {
            return ['division', 'status']
        },
        available_filters () {
            return this.server_available_filters
        },
		items () {
            return this.responseData
        },
		allowDeletePagePermanently () {
            if (this.currentDeletedPage === null) { return false }
            return this.currentDeletedPage.status === 'Trash'
        },
		hasManageContentPermission () {
			return this.hasPermission(['manage:Content'])
		},
    },
	watch: {
		doShowEditorDialog (v) {
			if (!v) {
				this.currentPage = null
				this.hubPagesEditorMode = EDITOR_MODE.new
			}
		},
		doShowConfirmDeleteDialog (v) {
			if (!v) {
				this.currentDeletedPage = null
			}
		},
	},
	methods: {
		loadData: debounce(async function (opt) {
            const params = {
				...opt,
                ...this.filters,
            }
            this.dataLoading = true
            const res = await getHubPages(params)
			if (res.error) {
				if (res.message === 'Aborted') { return }
				this.setErrorResponseData()
				console.log(res.error)
				this.showError('Whoops! There was a problem loading Hub Pages.<br>', res.message)
			} else {
				this.setResponseData(res)
				this.dataLoading = false
			}
            this.firstPull = false
        }, 200),
		setResponseData (response) {
            this.responseData = response.data
            this.serverItemsLength = response.meta?.total
            if (response.meta?.filters) {
                this.server_available_filters = response.meta.filters
            }
        },
		setErrorResponseData () {
            this.responseData = []
            this.serverItemsLength = undefined
        },
		addNewPage () {
			this.currentPage = null
			this.hubPagesEditorMode = EDITOR_MODE.new
			this.doShowEditorDialog = true
		},
		copyPage (page) {
			this.currentPage = { ...page }
			this.hubPagesEditorMode = EDITOR_MODE.copy
			this.doShowEditorDialog = true
		},
		editPage (page) {
			this.currentPage = { ...page }
			this.hubPagesEditorMode = EDITOR_MODE.edit
			this.doShowEditorDialog = true
		},
		viewPage (page) {
			this.$router.push({ name: 'HubPage', params: { slug : page.slug } })
		},
		viewAudit (page) {
			this.$router.push({ name: 'HubPageAudit', params: { pageId : page.id } })
		},
		confirmDeletePage (page) {
			this.currentDeletedPage = { ...page }
            this.doShowConfirmDeleteDialog = true
		},
		async deletePage () {
            const name = this.currentDeletedPage.title
			this.pageDeleting = true
			const res = await deleteHubPage(this.currentDeletedPage.id)
			if (res.error) {
				console.log(res.error)
				this.showError('Whoops! There was a problem deleting Hub Page.<br>', res.message)
			} else {
				this.refreshData()
				this.showInfo(`"${name}" has been deleted.<br>`)
				this.doShowConfirmDeleteDialog = false
				this.pageDeleting = false
			}
        },
		onHubPageSave () {
			this.refreshData()
			this.doShowEditorDialog = false
		},
		resetAndReload () {
            this.search = null
            this.filters.division = ALL_OPTION
            this.filters.status = ALL_OPTION
			this.refreshData(true)
		},
		onSearchClear () {
			this.search = null
			this.refreshData()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.hub-pages {
	--padding: 1rem;

	&.hub-pages--mobile {
		--padding: 0.25rem;
	}

	padding: var(--padding);

	.data-table-container__row {
		width: 100%;
		display: flex;
		flex-wrap: wrap-reverse;
		gap: 1rem 3rem;

		.data-table-container__col {
			max-width: 100%;
		}

		.data-table-col {
			flex: 10 10 600px;
			overflow-x: auto;
			padding: 12px;
		}

		.filters-col {
			flex: 1 0 280px;
		}
	}

	.data-table-header-actions {
		margin: 1rem 0.75rem;
		display: flex;
		flex-wrap: wrap;
		gap: 1rem 2rem;
	}
}
</style>
